.footer {
    background: linear-gradient(to top, rgba(0,0,128, 1) 0%, rgba(0,0,128, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
  }
  
  /* Footer.css */
.table-transparent {
    background-color: transparent;
  }
  
  .table-transparent th,
  .table-transparent td {
    background-color: transparent;
    border-color: transparent;
  }
  

  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  
  .footer-left,
  .footer-right {
    width: 50%;
    margin-bottom: 70px; /* Desktop */
  }
  
  @media (max-width: 768px) {
    .footer-left,
    .footer-right {
      margin-bottom: 50px; /* Mobile */
    }
  }
  