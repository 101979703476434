/*Meal.css*/
.child-tile.checked-in {
    background-color: lightblue;
}

.modal-option-tile {
width: 100px; /* gleiche Breite und Höhe */
height: 100px;
border: 1px solid #ccc;
border-radius: 0; /* Ecken entfernen */
padding: 10px;
margin: 5px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
transition: background-color 0.3s ease;
}

.modal-option-tile:hover {
background-color: lightgrey;
}

.modal-option-tile.selected {
background-color: lightgreen;
}

.modal-category {
margin-bottom: 20px;
}

.modal-category h5 {
text-align: center; /* Texte zentrieren */
margin-bottom: 10px;
}

textarea {
width: 100%;
padding: 10px;
border-radius: 5px;
border: 1px solid #ccc;
}

.modal-amount-tile {
    width: 80px; /* Breite der Smiley-Tiles */
    height: 80px; /* Höhe der Smiley-Tiles */
    border: 1px solid #ccc;
    border-radius: 50%; /* Runde Form */
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.modal-amount-tile img {
width: 50px; /* Breite der Smiley-Bilder */
height: 50px; /* Höhe der Smiley-Bilder */
}

.modal-amount-tile:hover {
background-color: lightgrey;
}

.modal-amount-tile.selected {
background-color: lightgreen;
}


.bottle-icon {
    width: 50px;
    height: 50px;
}

.slider {
width: 100%;
margin: 0 10px;
}