.alert-box {
    border: 2px solid red;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff3cd; /* Heller gelber Hintergrund */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Abstand nach unten */
  }
  
  .danger-icon {
    color: #ffc107; /* Gelbe Farbe für das Danger-Icon */
    font-size: 40px; /* Größere Schriftgröße für das Icon */
    margin-bottom: 10px; /* Abstand nach unten */
  }
  
  .alert-text {
    color: #856404; /* Dunklere gelbe Farbe für den Text */
    text-align: center; /* Text zentrieren */
    font-size: 14px; /* Schriftgröße */
  }
  