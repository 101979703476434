/* Home.css */

.tiles-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 Kacheln pro Reihe auf Desktop */
  gap: 20px;
}

.tile {
  background-color: #f0f0f0;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Wichtig für die Positionierung des Inhalts */
  display: flex; /* Flexbox für die Ausrichtung des Inhalts */
  justify-content: center; /* Zentriert den Inhalt horizontal */
  align-items: center; /* Zentriert den Inhalt vertikal */
}

.tile::before {
  content: '';
  display: block;
  padding-top: 100%; /* Verhältnis von 1:1, macht es quadratisch */
}

.tile-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.tile:hover {
  background-color: lightgrey;
}

/* Media Queries */
@media (min-width: 769px) and (max-width: 1600px) { /* Für Tablets */
  .tiles-container {
    grid-template-columns: repeat(3, 1fr); /* 3 Kacheln pro Reihe */
  }
}

@media (max-width: 768px) { /* Für Mobile */
  .tiles-container {
    grid-template-columns: repeat(2, 1fr); /* 2 Kacheln pro Reihe */
    gap: 10px; /* Abstand zwischen den Kacheln */
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 100%; /* Maximalbreite auf Desktop */
}

.modal-tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 Kacheln pro Zeile */
  gap: 10px;
}

@media (min-width: 769px) and (max-width: 1600px) { /* Für Tablets */
  .modal-content {
    max-width: 100%; /* Maximalbreite auf Tablets */
  }
}

@media (max-width: 768px) { /* Für Mobile */
  .modal-content {
    max-width: 100%; /* Volle Breite auf Mobile */
  }
  .modal-tiles {
    grid-template-columns: repeat(2, 1fr); /* 2 Kacheln pro Zeile auf Mobile */
    gap: 10px; /* Abstand zwischen den Kacheln */
  }
}

.modal-tile {
  background-color: #f0f0f0;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Wichtig für die Positionierung des Inhalts */
  display: flex; /* Flexbox für die Ausrichtung des Inhalts */
  justify-content: center; /* Zentriert den Inhalt horizontal */
  align-items: center; /* Zentriert den Inhalt vertikal */
}

.modal-tile::before {
  content: '';
  display: block;
  padding-top: 100%; /* Verhältnis von 1:1, macht es quadratisch */
}

.modal-tile:hover {
  background-color: lightgrey;
}
