/* styles/ShowOperations.css */

.view-mode-toggle {
    text-align: right; /* Rechtsbündig für den Ansichtsmodus-Umschaltknopf */
    margin-bottom: 20px; /* Abstand nach unten */
    margin-top: 20px; /* Abstand nach oben */
  }

/* Stiländerungen für die Timeline */
.timeline-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Positionierung und Stil des Datums und der Uhrzeit */
  .timeline-date {
    margin-bottom: 0px;
  }
  
  .timeline-time {
    position: relative;
    border: 2px solid black;
    border-radius: 50%;
    line-height: 100px;
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    animation: pulse 2s infinite;
    background-color: white;
  }
  
  /* Entfernt den überflüssigen Strich über der Uhr */
  .timeline-time::before {
    display: none;
  }

/* Fügt der timeline-time Klasse ein Pseudo-Element hinzu, um die Mittellinie zu erzeugen */
.timeline-time::after {
   content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 2px;
    height: 50px;
    background-color: #0d6efd;
    transform: translateX(-50%);
    z-index: -1;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #0d6efd;
    }
    70% {
      box-shadow: 0 0 0 10px #007bff00;
    }
    100% {
      box-shadow: 0 0 0 0 #007bff00;
    }
  }
  
/* Die timeline-operation-entry Struktur */
.operation-entry {
    display: flex;
    align-items: center; /* Zentriert Elemente vertikal */
    position: relative; /* Wichtig, um die position:absolute für die Icons zu nutzen */
    margin-bottom: 20px; 
}

/* Fügt eine Linie hinzu, die das Icon mit dem Text verbindet */
.operation-entry::before {
  content: '';
  position: absolute;
  top: 50%; /* Positioniert die Linie vertikal zentriert */
  height: 2px; /* Höhe der Linie */
  width: calc(50% + 60px); /* Länge der Linie (50% minus die Hälfte des Abstands zwischen Icon und Text) */
  background-color: #0d6efd; /* Farbe der Linie */
  z-index: -1; /* Bringt die Linie hinter den Text und das Icon */
}

  
/* Linke Seite des Eintrags - Zeit der Operation */
.operation-date {
  flex: 1;
  text-align: right;
  margin-right: 60px; /* Abstand zum Icon hinzufügen */
  padding: 10px; /* Hinzufügen von Padding */
  background-color: #f0f0f0; /* Hintergrundfarbe wie beim Text */
  border-radius: 8px; /* Runde Ecken wie beim Text */
}
  
/* Container für das Icon */
.operation-icon-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 60px; /* Breite des Containers erhöhen */
  height: 60px; /* Höhe des Containers erhöhen */
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
}

/* Container für das Icon */
.operation-icon-container::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 2px;
  height: 55px; /* Höhe der Linie zwischen den Icons */
  background-color: #0d6efd;
  transform: translateX(-50%);
  z-index: -1;
}

/* Entfernt die Linie beim letzten Icon */
.operation-entry:last-child .operation-icon-container::after {
  display: none;
}

/* Das Icon in der Mitte */
.operation-icon {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px; /* Hinzufügen von Padding */
}


/* Rechte Seite des Eintrags - Text */
.operation-text {
    flex: 1;
    margin-left: 60px; /* Abstand zum Icon hinzufügen */
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.operation-text.highlight {
  border: 2px solid darkgrey;
}

.no-data-message {
  margin: 60px 0;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.spinner-with-margin {
  margin-top: 65px;
}


/* Kinder-Icons */
.children-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Abstand zwischen den Icons */
  margin-bottom: 10px;
}

.child-icon-container {
  flex: 0 1 80px; /* Icons passen sich der Breite an, mindestens 80px */
}

.child-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  transition: border 0.3s; /* Hinzufügen einer sanften Übergangsanimation */
}

.child-icon.highlight {
  border: 2px solid darkgrey;
}

.highlight-comment {
  background-color: yellow;
}

strong {
  display: block;
  font-weight: bold;
}


/*background of icons*/
.yellow-background {
  background-color: yellow;
}

.child-name {
  text-align: center;
  margin-top: 5px;
}

/* Gruppenkacheln Container */
.group-tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Zentriert die Kacheln horizontal */
  margin-bottom: 10px;
}

/* Einzelne Gruppenkachel */
.group-tile {
  border-radius: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin: 10px;
  background-color: #f0f0f0; /* Hintergrundfarbe für Gruppenkacheln */
  padding: 20px;
  flex: 1 1 calc(25% - 40px); /* Passt sich an die Breite des Containers an, max. 4 Kacheln pro Reihe */
  box-sizing: border-box;
}

.group-tile:hover {
  background-color: lightgrey;
}

.group-tile.selected {
  background-color: lightgreen;
}

.group-tile p {
  margin-top: 10px; /* Abstand zwischen Texten */
  font-size: 18px; /* Schriftgröße für Gruppennamen */
  text-align: center; /* Zentrierung des Textes */
}

/* Für Smartphones */
@media (max-width: 767px) {
  .group-tile {
    flex: 1 1 calc(33.33% - 30px); /* Passt sich an, max. 2 Kacheln pro Reihe */
    margin: 5px;
  }
}

/* Für Tablets (768px bis 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .group-tile {
    flex: 1 1 calc(33.33% - 30px); /* Passt sich an, max. 3 Kacheln pro Reihe */
    margin: 10px;
  }
}
