/*CheckinChild.css*/
.child-tile.checked-in {
  background-color: lightblue;
}

.modal-option-tile {
  width: 100px; /* gleiche Breite und Höhe */
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 0; /* Ecken entfernen */
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s ease;
}

.modal-option-tile:hover {
  background-color: lightgrey;
}

.modal-option-tile.selected {
  background-color: lightgreen;
}

.modal-category {
  margin-bottom: 20px;
}

.modal-category h5 {
  text-align: center; /* Texte zentrieren */
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}


.missing-field h5 {
  color: red;
  animation: highlight 1s ease-in-out;
}

@keyframes highlight {
  0% {
    color: red;
  }
  100% {
    color: inherit;
  }
}
