.d-flex.flex-column.align-items-center.p-2 {
    padding: 10px;
    text-align: center;
  }
  
  .rounded-circle.mb-2 {
    margin-bottom: 10px;
    border: 2px solid #ccc;
  }
  
  .child-tile {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .child-tile:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: lightgrey;
  }
  
  .child-tile.selected {
    background-color: lightgreen;
  }
  