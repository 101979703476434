/*CheckinChild.css*/

.modal-option-tile {
  width: 100px; /* gleiche Breite und Höhe */
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 0; /* Ecken entfernen */
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s ease;
}

.modal-option-tile:hover {
  background-color: lightgrey;
}

.modal-option-tile.selected {
  background-color: lightgreen;
}

.modal-category {
  margin-bottom: 20px;
}

.modal-category h5 {
  text-align: center; /* Texte zentrieren */
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}


/* Die timeline-operation-entry Struktur */
.operation-entry-checkout {
  display: flex;
  align-items: center; /* Zentriert Elemente vertikal */
  margin-bottom: 20px; 
}

/* Fügt eine Linie hinzu, die das Icon mit dem Text verbindet */
.operation-entry-checkout::before {
content: '';
position: absolute;
top: 50%; /* Positioniert die Linie vertikal zentriert */
height: 2px; /* Höhe der Linie */
width: calc(50% + 60px); /* Länge der Linie (50% minus die Hälfte des Abstands zwischen Icon und Text) */
background-color: #0d6efd; /* Farbe der Linie */
z-index: -1; /* Bringt die Linie hinter den Text und das Icon */
}

/* Linke Seite des Eintrags - Zeit der Operation */
.operation-date-checkout {
  flex-shrink: 0; /* Verhindert das Vergrößern */
  text-align: right;
  margin-right: 10px; /* Abstand zum Icon hinzufügen */
  padding: 10px; /* Hinzufügen von Padding */
  background-color: #f0f0f0; /* Hintergrundfarbe wie beim Text */
  border-radius: 8px; /* Runde Ecken wie beim Text */
  white-space: nowrap; /* Verhindert Zeilenumbruch */
}
  
/* Container für das Icon */
.operation-icon-container-checkout {
  flex-shrink: 0; /* Verhindert das Vergrößern */
  width: 50px; /* Breite des Containers erhöhen */
  height: 50px; /* Höhe des Containers erhöhen */
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; /* Abstand zum Text hinzufügen */
}

/* Container für das Icon */
.operation-icon-container-checkout::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 2px;
  height: 55px; /* Höhe der Linie zwischen den Icons */
  background-color: #0d6efd;
  transform: translateX(-50%);
  z-index: -1;
}


/* Entfernt die Linie beim letzten Icon */
.operation-entry-checkout:last-child .operation-icon-container-checkout::after {
  display: none;
}

/* Das Icon in der Mitte */
.operation-icon-checkout {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
}


/* Rechte Seite des Eintrags - Text */
.operation-text-checkout {
  flex-grow: 1; /* Vergrößert sich, um den restlichen Platz zu füllen */
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.operation-text-checkout.highlight {
  border: 2px solid darkgrey;
}