/*Operations.css*/

/* Grundlegendes Grid-Layout für die Kacheln */
.child-tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.child-tile {
  border-radius: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin: 10px;
}

/* Für Smartphones */
@media (max-width: 767px) {
  .child-tile {
    margin: 10px;
  }
  .row.no-gutters {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}

/* Für Tablets (768px bis 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .child-tiles-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.child-tile:hover {
  background-color: lightgrey;
}

.child-tile.selected {
  background-color: lightgreen;
}

.child-tile img {
  width: 100px; /* Breite des Bildes */
  height: 100px; /* Höhe des Bildes */
  border-radius: 50%; /* Kreisförmiges Bild */
  object-fit: cover; /* Stellt sicher, dass das Bild den Kreis vollständig ausfüllt */
}

.child-tile p {
  margin-top: 10px; /* Abstand zwischen Bild und Text */
}

.overdue {
  animation: pulseTile 2s infinite;
  background-color: white;
}

/* Entfernt den überflüssigen Strich über der Uhr */
.overdue::before {
  display: none;
}

/* Fügt der overdue Klasse ein Pseudo-Element hinzu, um die Mittellinie zu erzeugen */
.overdue::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  width: 2px;
  height: 400px;
  transform: translateX(-50%);
  z-index: -1;
}

@keyframes pulseTile {
  0% {
    box-shadow: 0 0 0 0 #fd0d0d;
  }
  70% {
    box-shadow: 0 0 0 10px #007bff00;
  }
  100% {
    box-shadow: 0 0 0 0 #007bff00;
  }
}

.child-tile.not-checkin {
  opacity: 0.4;
  cursor: pointer;
}

.child-tile.checked-out {
  background-color: lightblue;
  opacity: 0.4;
}

.child-tile.disabled-sleep {
  pointer-events: none;
  opacity: 0.4;
}
