/* SignOperation.css */

/* Grundlegende Stile für Kacheln */
.tileSO {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px;
    border: 1px solid #dee2e6;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tileSO:hover {
    background-color: lightgrey;
  }
  
  /* Mitarbeiter-Kacheln */
  .employee-tiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .employee-tile.selected {
    background-color: #e9ecef;
  }

  .employee-tile::before {
    content: '';
    display: block;
    padding-top: 100%; /* Verhältnis von 1:1, macht es quadratisch */
  }
  
  /* Tastatur und Eingabeanzeige */
.keyboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Erstellen Sie ein 3-Spalten-Grid für die Tasten */
    gap: 10px; /* Abstand zwischen den Tasten */
    margin-bottom: 20px; /* Abstand unterhalb der Tastatur */
  }
  
  .key {
    display: flex; /* Ermöglicht es, Inhalte innerhalb der Kacheln zu zentrieren */
    justify-content: center; /* Zentriert Inhalte horizontal */
    align-items: center; /* Zentriert Inhalte vertikal */
    background-color: #f8f9fa;
    padding: 20px; /* Veränderbar, um die Größe der Tasten zu beeinflussen */
    border: 1px solid #dee2e6;
    cursor: pointer; /* Zeigt den Cursor als Zeiger */
    font-size: 1.5rem; /* Vergrößern Sie die Schriftgröße der Tasten */
  }
  
  .key.zero {
    grid-column: 2 / span 1; /* Zentriert die Taste '0' */
  }
  
  /* Stile für den Lösch-Button innerhalb der Tastatur */
  .keyboard .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none; /* Verhindert das Auswählen des Textsymbols */
    color: #591212;
  }
  
  .button-group .btn-secondary {
    margin-left: 0; /* Setzt den Zurück-Button ganz nach links */
  }
  
  /* Eingabeanzeige */
  .code-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ddd; /* Farbe für leere Punkte */
    margin: 0 5px; /* Fügt horizontalen Abstand zwischen den Punkten hinzu */
  }
  
  .dot.filled {
    background-color: #591212; /* Farbe für ausgefüllte Punkte */
  }
  
  /* Anpassungen für die Positionierung des Zurück-Buttons */
  .button-group {
    display: grid;
    grid-template-areas: "back save";
    grid-template-columns: 1fr auto;
  }
  
  .button-group .btn-secondary {
    grid-area: back; /* Platziert den Zurück-Button links */
  }
  
  .button-group .btn-primary {
    grid-area: save; /* Platziert den Speichern-Button rechts */
  }
  
  /* Neue Klasse für das Erfolgsbild */
  .success-image {
    max-width: 100%;
    height: auto;
  }